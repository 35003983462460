import React, { useEffect } from 'react';
import Menu from '../../components/Menu/Menu';

const Pay = () => {
    const pricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
    const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    const pricingTableAnualId = process.env.REACT_APP_STRIPE_PUBLISHABLE__ANUAL_KEY;
    const publishableAnualKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_ANUAL_KEY;

    

    useEffect(() => {
        // Checa se o script já existe antes de adicioná-lo
        if (!document.querySelector('script[src="https://js.stripe.com/v3/pricing-table.js"]')) {
            const script = document.createElement('script');
            script.src = 'https://js.stripe.com/v3/pricing-table.js';
            script.async = true;
            document.body.appendChild(script);
        }
    }, []);

    return (
        <>
            <main className="content">
                <Menu />
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-6">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4">Subscription Plan</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="mb-10 align-items-center">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: `<stripe-pricing-table
                          pricing-table-id="${pricingTableId}"
                          publishable-key="${publishableKey}">
                        </stripe-pricing-table>`,
                            }}
                        />
                    </div>
                    <div className="mb-4 align-items-center">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: `<stripe-pricing-table
                          pricing-table-id="${pricingTableAnualId}"
                          publishable-key="${publishableAnualKey}">
                        </stripe-pricing-table>`,
                            }}
                        />
                    </div>
                </div>
            </main >
        </>
    );
};

export default Pay;
