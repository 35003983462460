import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Ticker from './Ticker/Ticker';
import Book from './Book/Book';
import useWebSocket from 'react-use-websocket';
import Wallet from '../../components/Wallet/Wallet';
import NewOrderButton from '../../components/NewOrder/NewOrderButton';
import NewOrderModal from '../../components/NewOrder/NewOrderModal';
import CandleChart from './CandleChart';
import { getDashboard } from '../../services/DashboardService';
import InfoBlock from './InfoBlock/InfoBlock';
import SelectSymbol from '../../components/SelectSymbol';
import Footer from '../../components/Footer';
import Toast from '../../components/Toast';

function Dashboard() {

  const navigate = useNavigate();

  const [tickerState, setTickerState] = useState({});

  const [balanceState, setBalanceState] = useState({});

  const [bookState, setBookState] = useState({});

  const [wallet, setWallet] = useState({});

  const [chartSymbol, setChartSymbol] = useState('BTCUSDT');

  const [notification, setNotification] = useState({ type: '', text: '' });

  const [report, setReport] = useState({});
  

  const { lastJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, {
    onOpen: () => {
      console.log(`Connected to App WS`);
    },
    onMessage: () => {
      if (lastJsonMessage) {
        if (lastJsonMessage.ticker) setTickerState(lastJsonMessage.ticker);
        else if (lastJsonMessage.balance) {
          setBalanceState(lastJsonMessage.balance);
        }
        else if (lastJsonMessage.book) {
          lastJsonMessage.book.forEach(b => bookState[b.symbol] = b);
          setBookState(bookState);
        }
      }
    },
    queryParams: { 'token': localStorage.getItem("token") },
    onError: (event) => {
      console.error(event);
      setNotification({ type: 'error', text: event });
    },
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: 3000
  });

  useEffect(() => {
    getDashboard()
      .then(result => setReport(result))
      .catch(error => {
        console.error(error.response ? error.response.data : error);
        setNotification({ type: 'error', text: error.response ? error.response.data : error });
      })
  }, [])

  function onWalletUpdate(walletObj) {
    setWallet(walletObj);
  }

  function onSubmitOrder(order) {
    navigate('/orders/' + order.symbol);
  }

  function onChangeSymbol(event) {
    setChartSymbol(event.target.value);
  }

  return (
    <>
      <Menu />
      <main className="content">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h1 className="h4">Dashboard</h1>
          </div>
          <div className="btn-toolbar mb-md-0">
            <div className="d-inline-flex align-items-center">
              <SelectSymbol onChange={onChangeSymbol} symbol={chartSymbol} />
            </div>
            <div className="ms-2 ms-lg-3">
              <NewOrderButton />
            </div>
          </div>
        </div>
        <CandleChart symbol={chartSymbol} />
        <div className="row">
          <InfoBlock title="Active Automations" value={report.automations} precision={0} background="success">
            <svg className="icon" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z">
              </path>
            </svg>
          </InfoBlock>
          <InfoBlock title="Active Monitors" value={report.monitors} precision={0} background="info">
            <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z" clipRule="evenodd" />
            </svg>
          </InfoBlock>
        </div>
        <div className="row">
          <div className="col-12">
            <Ticker data={tickerState} />
          </div>
        </div>
        <div className="row">
          <Book data={bookState} />
          <Wallet data={balanceState} onUpdate={onWalletUpdate} />
        </div>
        <Footer />
      </main>
      <NewOrderModal wallet={wallet} onSubmit={onSubmitOrder} />
      <Toast type={notification.type} text={notification.text} />
    </>
  );
}

export default Dashboard;
