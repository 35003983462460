import React, { useEffect, useState } from "react";
import useWebSocket from 'react-use-websocket';

/**
 * props:
 * - text
 * - type
 */
function Toast(props) {
    const DEFAULT_NOTIFICATION = { type: '', text: '' };

    const [notification, setNotification] = useState(DEFAULT_NOTIFICATION);

    useEffect(() => {
        if (!notification.text) return;

        // Cria uma nova instância do Notyf com estilo e duração configurados
        const notyf = new window.Notyf({
            position: {
                x: 'center', // Altera para o centro
                y: 'top',    // Altera para o topo
            },
            duration: 20000, // Duração de 20 segundos
            types: [
                {
                    type: 'info',
                    background: '#3498db', // Azul claro
                    dismissible: true,
                    icon: false, // Remover ícone para maior foco no texto
                },
                {
                    type: 'error',
                    background: '#e74c3c', // Vermelho
                    dismissible: true,
                    icon: false,
                },
                {
                    type: 'success',
                    background: '#2ecc71', // Verde claro
                    dismissible: true,
                    icon: false,
                }
            ],
            ripple: true, // Adiciona efeito de onda ao clicar
            dismissible: true, // Permite que o usuário feche manualmente
            borderRadius: '15px', // Bordas arredondadas
        });

        // Exibe a notificação com o tipo e texto
        notyf.open({
            type: notification.type,
            message: notification.text
        })
        .on('dismiss', () => {
            setNotification(DEFAULT_NOTIFICATION);
        });

    }, [notification]);

    useEffect(() => {
        setNotification({ type: props.type, text: props.text });
    }, [props.type, props.text]);

    const { lastJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, {
        onOpen: () => {
            console.log(`Connected to App WS`);
        },
        onMessage: () => {
            if (lastJsonMessage && lastJsonMessage.notification) {
                setNotification(lastJsonMessage.notification);
            }
        },
        queryParams: { 'token': localStorage.getItem("token") },
        onError: (event) => {
            console.error(event);
            setNotification({ text: JSON.stringify(event), type: 'error' });
        },
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 3000
    });

    return null; // Não precisa renderizar nada diretamente no JSX
}

export default Toast;
